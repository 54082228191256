import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2310 3700 c-127 -34 -227 -83 -309 -149 -25 -20 -49 -39 -55 -43 -8
-6 -6 -11 4 -18 12 -8 10 -10 -10 -8 -20 3 -25 -1 -25 -24 -1 -14 -7 -34 -15
-43 -12 -15 -12 -16 2 -13 9 1 17 0 17 -4 3 -27 -1 -37 -13 -41 -10 -4 -13 -1
-9 9 3 7 1 14 -5 14 -5 0 -13 -7 -16 -16 -9 -22 -8 -24 10 -24 8 0 13 -4 9 -9
-3 -5 -1 -12 4 -15 11 -7 3 -76 -10 -90 -5 -6 -9 -17 -9 -25 0 -19 33 18 55
64 8 17 35 52 59 80 24 27 42 54 39 59 -4 5 -1 6 6 2 6 -4 30 7 54 26 51 40
74 55 107 68 38 16 72 31 99 44 21 9 100 142 101 169 0 10 -15 8 -90 -13z
m-314 -211 c19 -22 19 -22 -3 -8 -13 8 -23 17 -23 21 0 12 6 9 26 -13z m64
-23 c0 -2 -9 -6 -20 -9 -11 -3 -18 -1 -14 4 5 9 34 13 34 5z m-100 -81 c0 -8
-4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m35 -15 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-25 -30 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-36 -45
c-9 -14 -18 -25 -20 -25 -2 0 2 11 8 25 6 14 15 25 20 25 5 0 1 -11 -8 -25z"/>
<path d="M2573 3706 c3 -8 21 -46 39 -85 27 -60 37 -71 63 -77 16 -3 53 -16
80 -27 245 -102 415 -351 415 -608 0 -215 -88 -404 -249 -534 -94 -76 -281
-160 -281 -126 0 9 71 222 184 550 20 58 40 109 46 115 19 19 10 34 -34 60
-37 22 -52 25 -90 20 -25 -4 -46 -9 -47 -13 0 -3 -2 -33 -5 -66 -3 -33 -9 -80
-14 -105 -5 -25 -18 -117 -30 -205 -12 -88 -32 -236 -46 -328 -32 -225 -36
-206 34 -195 329 51 588 295 677 638 24 93 16 316 -15 414 -89 280 -314 494
-597 565 -98 25 -137 27 -130 7z m224 -762 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m48 -45 c-4 -5 -13 -9 -21 -9 -12 0 -12 2 -2 14 14 15 32
11 23 -5z"/>
<path d="M1875 3430 c-16 -17 -24 -30 -19 -30 12 0 58 50 52 56 -3 2 -17 -10
-33 -26z"/>
<path d="M2433 3387 c-17 -7 -34 -14 -37 -17 -3 -3 8 -147 24 -320 15 -173 36
-443 46 -600 l19 -285 12 175 c23 342 34 488 59 759 13 151 22 276 19 279 -3
3 -5 3 -5 -1 0 -3 -16 1 -35 9 -40 16 -59 17 -102 1z"/>
<path d="M1818 3355 c-22 -29 -23 -42 -3 -25 13 10 15 9 15 -8 0 -26 16 -37
24 -16 8 21 8 26 -3 20 -12 -8 -22 20 -15 39 9 22 2 18 -18 -10z"/>
<path d="M1776 3284 c-10 -26 -6 -30 8 -11 14 18 15 18 25 0 9 -16 10 -16 11
-2 0 9 -7 20 -16 23 -21 8 -21 8 -28 -10z"/>
<path d="M1831 3269 c-1 -18 20 -82 25 -77 2 2 -3 24 -11 48 -7 25 -14 38 -14
29z"/>
<path d="M1737 3203 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M1864 3158 c-6 -20 -8 -21 -13 -5 -4 10 -7 -8 -7 -39 l-1 -58 18 59
c10 33 16 61 14 63 -2 2 -7 -7 -11 -20z"/>
<path d="M1720 3160 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1706 3125 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M1825 2999 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1810 2954 c0 -11 -6 -26 -12 -33 -10 -11 -10 -13 0 -7 9 5 12 -8 12
-56 0 -44 5 -70 16 -84 15 -18 15 -18 9 6 -4 14 -9 61 -12 105 -2 44 -6 82 -9
84 -2 2 -4 -5 -4 -15z"/>
<path d="M2186 2903 c-12 -7 -31 -18 -44 -25 -26 -13 -27 -8 16 -123 17 -44
61 -163 98 -265 37 -102 71 -186 76 -188 10 -5 10 -49 0 -56 -17 -10 -188 76
-259 130 -40 31 -73 60 -73 65 0 4 -16 6 -35 4 -30 -4 -34 -3 -23 9 9 10 15
11 20 3 4 -6 10 -8 14 -5 3 4 -11 33 -32 65 -48 71 -70 114 -89 173 l-14 45 6
-50 c3 -27 10 -55 14 -61 5 -6 6 -20 2 -30 -6 -17 -5 -17 10 -5 15 12 17 11
17 -14 0 -21 -4 -26 -16 -21 -12 5 -15 2 -10 -10 3 -8 6 -18 6 -20 0 -2 7 -4
16 -4 8 0 12 5 9 10 -3 6 -1 10 4 10 6 0 11 -7 11 -16 0 -8 4 -13 8 -10 4 2
13 -4 20 -15 7 -11 8 -19 2 -19 -5 0 -10 4 -10 8 0 4 -8 8 -18 9 -10 1 -19 -5
-19 -13 0 -8 5 -14 10 -14 6 0 7 -3 4 -7 -4 -3 -1 -13 6 -21 10 -13 8 -14 -15
-8 -21 5 -28 3 -28 -9 0 -9 9 -15 25 -15 28 0 33 -15 9 -24 -13 -5 -14 -9 -4
-21 7 -8 16 -12 21 -9 5 3 6 -2 3 -10 -4 -11 0 -16 12 -17 12 0 14 -3 6 -6
-15 -6 -57 36 -67 67 -3 11 -11 20 -16 20 -5 0 -9 7 -9 16 0 8 -4 13 -9 10 -4
-3 -19 15 -31 39 -13 25 -27 45 -32 45 -16 0 48 -104 99 -160 17 -19 41 -46
53 -60 18 -21 23 -22 32 -10 8 12 9 12 4 -2 -4 -12 6 -26 32 -45 20 -15 39
-31 42 -34 14 -17 174 -89 241 -108 101 -28 123 -28 115 2 -11 36 -24 143 -56
452 -40 388 -31 352 -87 357 -25 2 -55 -2 -67 -9z m-156 -523 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-59 -37 c13 -16 12
-17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m25 -62 c-3 -4 6 -13 21 -20
14 -6 23 -14 20 -17 -8 -9 -58 27 -51 37 3 5 7 9 10 9 3 0 3 -4 0 -9z m361
-53 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1791 2774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1692 2740 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1707 2660 c3 -11 9 -22 13 -25 5 -3 6 4 3 15 -3 11 -9 22 -13 25 -5
3 -6 -4 -3 -15z"/>
<path d="M1741 2634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1842 2590 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1737 2580 c3 -11 10 -23 16 -27 7 -4 8 0 4 11 -4 10 -7 22 -7 27 0
5 -4 9 -9 9 -5 0 -7 -9 -4 -20z"/>
<path d="M1831 2474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2670 1760 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2995 1760 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1338 1753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1728 1744 c-37 -19 -36 -25 2 -11 17 6 30 15 30 19 0 10 0 10 -32
-8z"/>
<path d="M1860 1745 c14 -8 32 -14 40 -14 9 0 4 6 -10 14 -14 8 -32 14 -40 14
-9 0 -4 -6 10 -14z"/>
<path d="M2058 1753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2161 1756 c2 -2 26 -6 53 -9 30 -3 43 -1 35 4 -13 8 -97 13 -88 5z"/>
<path d="M2444 1744 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
<path d="M2498 1753 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2713 1753 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2940 1750 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3588 1753 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M2601 1743 c4 -23 -3 -35 -16 -29 -8 3 -15 1 -15 -4 0 -16 37 -11 44
6 9 23 7 34 -4 34 -5 0 -9 -3 -9 -7z"/>
<path d="M1331 1724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2680 1735 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2752 1720 c7 -10 14 -18 15 -16 6 6 -9 36 -18 36 -5 0 -4 -9 3 -20z"/>
<path d="M2538 1713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2976 1705 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M1851 1708 c2 -2 18 -14 34 -28 l30 -25 -24 28 c-14 15 -29 27 -34
27 -6 0 -8 -1 -6 -2z"/>
<path d="M2230 1700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2443 1695 c-9 -24 1 -27 15 -5 8 13 8 20 2 20 -6 0 -13 -7 -17 -15z"/>
<path d="M2382 1665 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1650 1661 c-5 -11 -10 -32 -9 -48 l1 -28 8 30 c4 17 11 38 15 48 10
23 -2 22 -15 -2z"/>
<path d="M2321 1664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2896 1654 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M1494 1540 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z"/>
<path d="M2581 1601 c13 -11 26 -17 29 -14 6 6 -30 33 -43 33 -5 0 1 -9 14
-19z"/>
<path d="M2800 1615 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3572 1590 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M2922 1578 c-10 -30 -10 -31 3 -14 13 17 21 46 12 46 -1 0 -8 -15
-15 -32z"/>
<path d="M2163 1583 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M1898 1557 c-3 -19 -2 -25 4 -19 6 6 8 18 6 28 -3 14 -5 12 -10 -9z"/>
<path d="M2575 1560 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2763 1540 c6 -16 13 -28 15 -26 6 6 -10 56 -18 56 -5 0 -3 -13 3
-30z"/>
<path d="M1650 1554 c0 -6 7 -19 16 -30 14 -18 14 -18 3 6 -14 31 -19 36 -19
24z"/>
<path d="M3621 1534 c-6 -15 -9 -29 -7 -32 3 -2 10 8 16 23 14 36 7 43 -9 9z"/>
<path d="M2120 1539 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2168 1543 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M1720 1530 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1940 1515 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M3550 1516 c-6 -8 -21 -17 -33 -19 -21 -4 -21 -4 -1 -6 11 0 29 8 39
19 10 11 16 20 13 20 -3 0 -11 -6 -18 -14z"/>
<path d="M1849 1503 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2390 1511 c0 -6 5 -13 12 -17 6 -4 8 -3 4 4 -3 5 0 13 6 15 8 3 6 6
-4 6 -10 1 -18 -3 -18 -8z"/>
<path d="M2449 1497 c5 -5 26 -7 47 -5 l39 3 -47 5 c-26 3 -43 1 -39 -3z"/>
<path d="M2614 1495 c-3 -8 -15 -17 -26 -21 -11 -3 -17 -8 -15 -11 8 -8 44 15
51 32 3 8 3 15 0 15 -2 0 -7 -7 -10 -15z"/>
<path d="M1808 1493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2380 1480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3397 1479 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M1818 1473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1855 1460 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M3535 1460 c-19 -8 -19 -9 2 -9 12 -1 25 4 28 9 7 12 -2 12 -30 0z"/>
<path d="M1773 1453 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2068 1453 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M2448 1453 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2513 1453 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3468 1453 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
